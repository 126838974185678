import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import _ from 'lodash';
import PulseLoader from 'react-spinners/PulseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Localbase from 'localbase';

import Header from '../layout/Header';
import Menu from '../layout/Menu';
import BottomNav from '../layout/BottomNav';
import Content from '../layout/Content';
import Loading from '../Loading';
import { DoorViewQuestion } from '../DoorViewQuestion';

import { saveSection, addDoor, saveRef, saveType, removeDoor, restoreDoor, savePhoto, removePhoto, saveOther, saveDoorToLocalStorage, saveFailedOption, saveFailedOther } from '../../actions/doorActions';
import { removeDoorPhoto, saveDoorPhoto, sortDoors } from '../../functions/utils';

import DoorRepairPopup from '../DoorRepairPopup';
import answers from '../../constants/answers';
import { LEFT_DOOR_MARKERS, RIGHT_DOOR_MARKERS } from '../../constants/dimensions';
import { DOOR_LEFT, DOOR_LEFT_NEXT, DOOR_RIGHT, DOOR_RIGHT_NEXT, PHOTOGRAPH_ONE, PHOTOGRAPH_TWO } from '../../constants/images';
import doorSections from '../../constants/doorSections';
import { CLEAR_DOOR_REDIRECT } from '../../types/doorTypes';

import styles from './styles/DoorView.module.css';

export const DoorView = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const doorSelector = useSelector(state => state.door);
    const { doors, saveToLocalStorage, redirectToNextDoor, loading, refLoading, otherLoading, repairsLoading, photoLoading } = doorSelector;

    const repairsSelector = useSelector(state => state.repairs);
    const { repairs } = repairsSelector;

    const doorsArray = _.values(doors);

    doorsArray.sort(sortDoors);

    const doorKeys = doorsArray.filter((door) => {
        return !door.deleted && !door.leftId;
    }).map((door) => door.id);
    
    const appSelector = useSelector(state => state.app);
    const { sections } = appSelector;

    const surveySelector = useSelector(state => state.survey);
    const { id: surveyId, surveyId: surveyRecordId, includeCosts } = surveySelector;
    
    const currentDoor = doors[id];

    const isRightDoor = currentDoor?.leftId ? true : false;
    const doorAnswers = currentDoor?.answers;

    // let rightDoor = _.values(doors).find((door) => door.leftId === id && !door.deleted);
    let rightDoor = _.values(doors).find((door) => door.leftId === id && !door.deleted);

    const [doorRef, setDoorRef] = useState(currentDoor?.ref === id ? '' : currentDoor?.ref);
    const [doorType, setDoorType] = useState(currentDoor?.type || '');
    const [typeError, setTypeError] = useState('');
    const [activeSection, setActiveSection] = useState(null);
    const [showControls, setShowControls] = useState(false);
    const [activeRepair, setActiveRepair] = useState(null);
    const [finishDoor, setFinishDoor] = useState(false);
    const [nextDoor, setNextDoor] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isFirstAttempt, setIsFirstAttempt] = useState(false);
    const [rightDoorCompleted, setRightDoorCompleted] = useState(false);
    const [otherInfo, setOtherInfo] = useState(false);
    const [otherText, setOtherText] = useState(currentDoor?.other?.text);
    const [otherCost, setOtherCost] = useState(currentDoor?.other?.cost);
    const [firstPhotoPreview, setFirstPhotoPreview] = useState('');
    const [secondPhotoPreview, setSecondPhotoPreview] = useState('');
    const [photoError, setPhotoError] = useState('');
    const [refError, setRefError] = useState('');
    const [firstRepairsCompleted, setFirstRepairsCompleted] = useState(false);

    const firstPhotoRef = useRef();
    const secondPhotoRef = useRef();

    let rightDoorID = null;
    let leftDoorID = null;

    if (rightDoor) {
        rightDoorID = rightDoor.id;
        leftDoorID = rightDoor.leftId;
    } else {
        // rightDoor = _.values(doors).find((door) => door.id === id && door.leftId !== null && !door.deleted);
        rightDoor = _.values(doors).find((door) => door.id === id && door.leftId !== null && !door.deleted);

        if (rightDoor) {
            // const leftDoor = _.values(doors).find((door) => door.id === rightDoor?.leftId && !door.deleted);
            const leftDoor = _.values(doors).find((door) => door.id === rightDoor?.leftId && !door.deleted);
            
            if (leftDoor) {
                rightDoorID = rightDoor.id;
                leftDoorID = rightDoor.leftId;
            }
        }
    }

    const isActiveLeftDoor = id === leftDoorID ? true : false;
    const isActiveRightDoor = id === rightDoorID ? true : false;

    const currentDoorIndex = isActiveRightDoor ? doorKeys.indexOf(leftDoorID) : doorKeys.indexOf(id);
    const nextDoorId = doorKeys[currentDoorIndex + 1];

    const checkDoorAnswersAreCompleted = () => {
        const unansweredQuestions = doors[id]?.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED) || [];

        if (unansweredQuestions.length > 0) {
            setIsCompleted(false);
        } else {
            setIsCompleted(true);
        }
    }

    const handleAddDoor = () => {
        if (doorType && firstPhotoPreview && doorRef) {
            dispatch(addDoor(null, null, "", true));
            setOtherInfo(false);
            firstPhotoRef.current.value = "";
            setFirstPhotoPreview('');
            secondPhotoRef.current.value = "";
            setSecondPhotoPreview('');
            setTypeError('');
            setPhotoError('');
            setRefError('');
        } else {
            if (!doorType) {
                setTypeError('You have not selected a type');
            }

            if (!firstPhotoPreview) {
                setPhotoError('You have not uploaded your first photograph');
            }

            if (!doorRef) {
                setRefError('You must provide a door reference');
            }
        }
    }

    const handleNextDoor = () => {
        if (doorType && firstPhotoPreview && doorRef) {
            setOtherInfo(false);
            firstPhotoRef.current.value = "";
            setFirstPhotoPreview('');
            secondPhotoRef.current.value = "";
            setSecondPhotoPreview('');
            setTypeError('');
            setPhotoError('');
            setRefError('');
            history.push(`/survey/doors/${nextDoorId}`);
        } else {
            if (!doorType) {
                setTypeError('You have not selected a type');
            }

            if (!firstPhotoPreview) {
                setPhotoError('You have not uploaded your first photograph');
            }

            if (!doorRef) {
                setRefError('You must provide a door reference');
            }
        }
    }

    const handleFinishDoor = () => {
        if (doorType && firstPhotoPreview && doorRef) {
            history.push('/survey/doors');

            setTypeError('');
            setPhotoError('');
            setRefError('');
        } else {
            if (!doorType) {
                setTypeError('You have not selected a type');
            }

            if (!firstPhotoPreview) {
                setPhotoError('You have not uploaded your first photograph');
            }
            
            if (!doorRef) {
                setRefError('You must provide a door reference');
            }
        }
    }
    
    const handleDoorRefBlur = (e) => {
        dispatch(saveRef(id, doorRef));

        setRefError('');
    }

    const handleFirstPhoto = async (event) => {
        const { files } = event.target;

        setFirstPhotoPreview(URL.createObjectURL(files[0]));

        await saveDoorPhoto(id, surveyId, 'photograph_1', files[0], files[0].name);

        const formData = new FormData();
        formData.append(doorSections.PHOTOGRAPH_ONE, files[0]);

        dispatch(savePhoto(id, formData, 'photograph_1', files[0].name));
        
        setPhotoError('');
    };

    const handleSecondPhoto = async (event) => {
        const { files } = event.target;

        setSecondPhotoPreview(URL.createObjectURL(files[0]));
        
        await saveDoorPhoto(id, surveyId, 'photograph_2', files[0], files[0].name);

        const formData = new FormData();
        formData.append(doorSections.PHOTOGRAPH_TWO, files[0]);

        dispatch(savePhoto(id, formData, 'photograph_2', files[0].name));
    };

    const handleRemovePhoto = (id, type) => {
        removeDoorPhoto(id, type);
        
        if (type === 'photograph_1') {
            dispatch(removePhoto(id, doorSections.PHOTOGRAPH_ONE));
            setFirstPhotoPreview(null);
            firstPhotoRef.current.value = "";
        } else {
            dispatch(removePhoto(id, doorSections.PHOTOGRAPH_TWO));
            setSecondPhotoPreview(null);
            secondPhotoRef.current.value = "";
        }
    }

    const nextQuestion = () => {
        if (activeSection) {
            const index = sections.findIndex((question) => question.id === activeSection.id);
            let nextIndex = 0;
    
            if (sections.length > index + 1) {
                nextIndex = index + 1;
                setActiveSection(sections[nextIndex]);
            } else {
                setActiveSection(null);
            }
        }
    }

    const handleOtherInfoSave = () => {
        dispatch(saveOther(id, otherText, otherCost));
        setOtherInfo(false);
    }

    const handleAnswerQuestion = (sectionId, answer) => {
        dispatch(saveSection(id, sectionId, answer));

        if (answer === answers.PASSED) {
            if (repairs) {
                const doorRepairs = repairs[currentDoor?.parentId]?.repairs;

                if (doorRepairs) {
                    const repair = doorRepairs.find((repair) => parseInt(repair?.field_id) === sectionId);
                    
                    if (repair) {
                        setActiveRepair(repair);
                    }
                }
            }
        }

        nextQuestion();
    }

    const handleFailedOption = (sectionId, option) => {
        dispatch(saveFailedOption(id, sectionId, option));
    }
    
    const handleSaveFailedOther = (sectionId, other, urgent) => {
        dispatch(saveSection(id, sectionId, answers.FAILED));
        dispatch(saveFailedOther(id, sectionId, other, urgent));
        nextQuestion();
    }

    const handleCloseQuestion = () => {
        setActiveSection(null);
        setShowControls(false);
    }

    const handleDoorType = (type) => {
        setDoorType(type);

        if ((type === '318' || type === '320') && !rightDoor) {
            // const rightDoorExists = _.values(doors).find((door) => door.leftId === id && door.deleted);
            const rightDoorExists = _.values(doors).find((door) => door.leftId === id && door.deleted);

            if (rightDoorExists) {
                dispatch(restoreDoor(rightDoorExists.id));
            } else {
                dispatch(addDoor(id, type, doorRef));
            }
        }

        if ((type === '317' || type === '319') && rightDoor) {
            dispatch(removeDoor(rightDoor.id));
        }

        dispatch(saveType(id, type));
    }

    const handleNextRepair = () => {
        if (!firstRepairsCompleted) {
            if (repairs) {
                const doorRepairs = repairs[currentDoor?.parentId]?.repairs;
        
                const tmpIndex = doorRepairs.findIndex((r) => r.appointment_id === activeRepair?.appointment_id);
        
                const nextRepair = doorRepairs[tmpIndex + 1];
        
                if (nextRepair) {
                    setActiveRepair(nextRepair);
                } else {
                    setActiveRepair(null);
                    setFirstRepairsCompleted(true);
                    checkDoorAnswersAreCompleted();
                }
            } else {
                setActiveRepair(null);
                setFirstRepairsCompleted(true);
                checkDoorAnswersAreCompleted();
            }
        } else {
            setActiveRepair(null);
        }
    }   

    useEffect(() => {
        if (isCompleted) {
            if (nextDoorId) {
                if (!rightDoor) {
                    setNextDoor(true);
                }

                if (isRightDoor) {
                    setNextDoor(true);
                }
            }
            
            setFinishDoor(true);
            setShowControls(false);
        }
    }, [nextDoorId, isCompleted, id]);

    useEffect(() => {
        const unansweredQuestions = doors[id]?.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED) || [];
        // const unansweredQuestions = _.filter(doors[id].answers, (a) => a.answer === answers.NOT_ANSWERED);

        if (unansweredQuestions.length > 0) {
            setIsCompleted(false);
            setIsFirstAttempt(true);
        } else {
            setIsCompleted(true);

            // setDebug(`isFirstAttempt: ${isFirstAttempt ? "true" : "false"}, rightDoor: ${rightDoor ? "true" : "false"}, rightDoorCompleted: ${rightDoorCompleted ? "true" : "false"}, nextDoorId: ${nextDoorId}`)
        
            if (isFirstAttempt && rightDoor && !rightDoorCompleted) {
                if (rightDoorID) {
                    history.push(`/survey/doors/${rightDoorID}`);
                }
            }
        }

        if (rightDoor) {
            const unansweredRightDoorQuestions = rightDoor?.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED) || [];
            // const unansweredRightDoorQuestions = _.filter(rightDoor.answers, (a) => a.answer === answers.NOT_ANSWERED);
    
            if (unansweredRightDoorQuestions.length > 0) {
                setRightDoorCompleted(false);
            } else {
                setRightDoorCompleted(true);
            }
        }
    }, [doors, id]);

    useEffect(() => {
        setActiveSection(null);
    }, [surveyRecordId]);

useEffect(() => {
        if (repairs) {
            const doorRepairs = repairs[currentDoor?.parentId]?.repairs;
    
            if (doorRepairs && !activeRepair) {

                const firstRepair = doorRepairs[0];
    
                if (firstRepair) {
                    setActiveRepair(firstRepair);
                }
            }
    
            if (activeRepair) {
                const tmpIndex = doorRepairs.findIndex((r) => r.appointment_id === activeRepair?.appointment_id);
            
                const nextRepair = doorRepairs[tmpIndex + 1];
        
                if (nextRepair) {
                    setActiveRepair(nextRepair);
                } else {
                    setActiveRepair(null);
                    setFirstRepairsCompleted(true);
                }
            }
        }
    }, [currentDoor?.parentId]);

    useEffect(() => {
        if (currentDoor) {
            setDoorRef(currentDoor?.ref === id ? '' : currentDoor?.ref);
            setDoorType(currentDoor?.type || '');
            setOtherText(currentDoor.other?.text);
            setOtherCost(currentDoor.other?.cost);
            setTypeError('');
            setPhotoError('');
            setRefError('');
            setFirstRepairsCompleted(false);
        }
    }, [currentDoor]);

    useEffect(() => {
        if (activeSection) {
            setShowControls(true);
        } else {
            setShowControls(false);
        }
    }, [activeSection]);

    useEffect(() => {
        async function loadPhotos() {
            const db = new Localbase('napfis-hotelapp');

            try {
                const document1 = await db.collection('door-photos').doc({ id: id, name: 'photograph_1' }).get();
                const document2 = await db.collection('door-photos').doc({ id: id, name: 'photograph_2' }).get();
        
                if (document1) {
                    setFirstPhotoPreview(URL.createObjectURL(document1.photo));
                }

                if (document2) {
                    setSecondPhotoPreview(URL.createObjectURL(document2.photo));
                }
            } catch (error) {
                console.error(error);
            }
        }

        loadPhotos();

        dispatch({ type: CLEAR_DOOR_REDIRECT });
    }, [currentDoor]);

    useEffect(() => {
        if (saveToLocalStorage) {
            dispatch(saveDoorToLocalStorage());
        }
    }, [saveToLocalStorage]);

    useEffect(() => {
        if (redirectToNextDoor) {
            history.push(`/survey/doors/${redirectToNextDoor}`);
        }
    }, [redirectToNextDoor]);

    return (
        <>
            <Header />
            <Menu />
            <Content style={{ marginTop: '100.27px' }}>
                <div>
                    {isFirstAttempt}
                    {showControls && activeSection && (
                        <DoorViewQuestion section={activeSection} doorId={id} onAnswerQuestion={handleAnswerQuestion} onFailedOption={handleFailedOption} onFailedOther={handleSaveFailedOther} onShowControls={handleCloseQuestion} />
                    )}

                    {activeRepair && (
                        <DoorRepairPopup id={activeRepair?.primary_id} appointmentId={activeRepair?.appointment_id} surveyId={surveyRecordId} fieldId={activeRepair?.field_id} doorRef={doorRef} message={activeRepair?.repair_message} onNextRepair={handleNextRepair} resetSection={!firstRepairsCompleted} />
                    )}

                    {/* Door Image */}
                    <div className={styles.doorContainer}>
                        <div className={styles.doorViewContainer}>
                            {isRightDoor && leftDoorID && (
                                <Link to={`/survey/doors/${leftDoorID}`}>
                                    <div className={styles.leftDoorNext}>
                                        <img src={`data:image/jpeg;base64,${DOOR_LEFT_NEXT}`} alt="Left door next" />
                                    </div>
                                </Link>
                            )}
                            <div className={styles.imageContainer}>
                                {doorType === '' ? (<div className={styles.doorOverlay}>Please select a door type</div>) : null}
                                {isRightDoor ? (
                                    <>
                                        <img src={`data:image/jpeg;base64,${DOOR_RIGHT}`} alt="Right door" />
                                        {RIGHT_DOOR_MARKERS.map((marker) => {
                                            const currentAnswer = doorAnswers?.find((answer) => answer.id === marker.id)?.answer;
                                            
                                            return marker.id === 572 ? (
                                                <div key={marker.id} className={`${styles.clickableTriangle} ${currentAnswer}-triangle`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'NA' && <span className={styles.iconStartPosition}>NA</span>}
                                                    <svg height="100" width="100">
                                                        <polygon points="50,20 16,80 84,80" class="triangle" />
                                                    </svg>
                                                    {currentAnswer === 'NOT_ANSWERED' && <span className={styles.startPosition}>Start</span>}
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faTimes} />}
                                                </div>
                                            ) : (
                                                <div key={marker.id} className={`${styles.clickable} ${currentAnswer} ${marker.id === activeSection?.id ? styles.activeMarker : ''}`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon icon={faTimes} />}
                                                    {currentAnswer === 'NA' && <span>NA</span>}
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <img src={`data:image/jpeg;base64,${DOOR_LEFT}`} alt="Left door" />
                                        {LEFT_DOOR_MARKERS.map((marker) => {
                                            const currentAnswer = doorAnswers?.find((answer) => answer.id === marker.id)?.answer;
                                            
                                            return marker.id === 572 ? (
                                                <div key={marker.id} className={`${styles.clickableTriangle} ${currentAnswer}-triangle`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'NA' && <span className={styles.iconStartPosition}>NA</span>}
                                                    <svg height="100" width="100">
                                                        <polygon points="50,20 16,80 84,80" className="triangle" />
                                                    </svg>
                                                    {currentAnswer === 'NOT_ANSWERED' && <span className={styles.startPosition}>Start</span>}
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faTimes} />}
                                                </div>
                                            ) : (
                                                <div key={marker.id} className={`${styles.clickable} ${currentAnswer} ${marker.id === activeSection?.id ? styles.activeMarker : ''}`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon icon={faTimes} />}
                                                    {currentAnswer === 'NA' && <span>NA</span>}
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                            {!isRightDoor && rightDoorID && (
                                <Link to={`/survey/doors/${rightDoorID}`}>
                                    <div className={styles.rightDoorNext}>
                                        <img src={`data:image/jpeg;base64,${DOOR_RIGHT_NEXT}`} alt="Right door next" />
                                    </div>
                                </Link>
                            )}
                        </div>
                        {/* Door Buttons */}
                        <div className={styles.doorButtons}>
                            <div>
                                <div className={styles.fieldBlock}>
                                    <input type="text" value={doorRef} placeholder="Door Reference" onChange={(e) => setDoorRef(e.target.value)} onBlur={handleDoorRefBlur} disabled={isActiveRightDoor || surveyRecordId} />
                                </div>
                                {refError && (
                                    <div className="error-message mt-2">{refError}</div>
                                )}
                                <div className={cls(typeError ? 'error-field' : '', styles.fieldBlock, 'mt-4')}>
                                    <select value={doorType} onChange={(e) => handleDoorType(e.target.value)} disabled={isActiveRightDoor || surveyRecordId}>
                                        <option value="">Please select a door type</option>
                                        <option value="317">FD30/s Single</option>
                                        <option value="318">FD30/s Double</option>
                                        <option value="319">FD60/s Single</option>
                                        <option value="320">FD60/s Double</option>
                                    </select>
                                </div>
                                {typeError && (
                                    <div className="error-message mt-2">{typeError}</div>
                                )}
                                <div className={styles.doorSelectButtons}>
                                    {rightDoorID && (
                                        <>
                                            <Link className={`${isActiveLeftDoor && styles.active} ${styles.doorButton}`} to={`/survey/doors/${leftDoorID}`}>Left</Link>
                                            <Link className={`${isActiveRightDoor && styles.active} ${styles.doorButton}`} to={`/survey/doors/${rightDoorID}`}>Right</Link>
                                        </>
                                    )}
                                    
                                </div>
                            </div>
                            <div className={styles.doorButtonsBottom}>
                                <div className={styles.photographContainer}>
                                    <img className={styles.photoIcon} src={`data:image/jpeg;base64,${PHOTOGRAPH_ONE}`} alt="Upload first photograph" style={{ marginRight: '1rem' }} />
                                    <input className={styles.photograph} type="file" onChange={handleFirstPhoto} ref={firstPhotoRef} placeholder="Choose File" accept="image/*" capture="camera" disabled={isActiveRightDoor} />
                                    {firstPhotoPreview && (
                                        <div className={styles.previewContainer}>
                                            <img className={styles.preview} src={firstPhotoPreview} alt="First photograph" />
                                            {!isRightDoor && (
                                                <button className={styles.removePhoto} onClick={() => handleRemovePhoto(id, 'photograph_1')}>X</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {photoError && (
                                    <div className="error-message mt-2">{photoError}</div>
                                )}
                                <div className={styles.photographContainer} style={{ marginTop: '1rem' }}>
                                    <img className={styles.photoIcon} src={`data:image/jpeg;base64,${PHOTOGRAPH_TWO}`} alt="Upload second photograph" style={{ marginRight: '1rem' }} />
                                    <input className={styles.photograph} type="file" onChange={handleSecondPhoto} ref={secondPhotoRef} placeholder="Choose File" accept="image/*" capture="camera" disabled={isActiveRightDoor} />
                                    {secondPhotoPreview && (
                                        <div className={styles.previewContainer}>
                                            <img className={styles.preview} src={secondPhotoPreview} alt="First photograph" />
                                            {!isRightDoor && (
                                                <button className={styles.removePhoto} onClick={() => handleRemovePhoto(id, 'photograph_2')}>X</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {otherInfo && (
                                    <div>
                                        <div className={styles.fieldBlock} style={{ marginTop: '1rem' }}>
                                            <textarea className={styles.textArea} value={otherText} placeholder={`Any additional comments${includeCosts === '1' ? ' & costs' : ''}`} onChange={(e) => setOtherText(e.target.value)} />
                                        </div>
                                        {includeCosts === '1' ? (
                                            <div style={{ display: 'flex', marginTop: '1rem', alignItems: 'center' }}>
                                                <span style={{ marginRight: '0.5rem' }}>&pound;</span>
                                                <div className={styles.fieldBlock}>
                                                    <input type="number" min={0} value={otherCost} onChange={(e) => setOtherCost(e.target.value)} />
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className={styles.buttonContainer}>
                                            <button className={styles.otherInfoButton} type="button" onClick={handleOtherInfoSave} disabled={otherLoading}>Save Other Info <PulseLoader loading={otherLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                                        </div>
                                    </div>
                                )}
                                {isCompleted && (
                                    <div className={styles.buttonContainer}>
                                        {!otherInfo && (
                                            <button className={styles.otherInfoButton} style={{ marginTop: '2rem' }} type="button" onClick={() => setOtherInfo(!otherInfo)}>Other Info</button>
                                        )}
                                        {nextDoor && nextDoorId && (
                                            <button className={styles.nextDoorButton} style={{ marginTop: '2rem' }} onClick={handleNextDoor}>Next Door</button>
                                        )}
                                        {!surveyRecordId && (
                                            <button className={styles.otherInfoButton} style={{ marginTop: '2rem' }} onClick={handleAddDoor}>Add Door</button>
                                        )}
                                        {finishDoor && (
                                            <button className={styles.finishButton} style={{ marginTop: '2rem' }} type="button" onClick={handleFinishDoor}>Finish Door</button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {loading || refLoading || otherLoading || repairsLoading || photoLoading && (
                    <Loading />
                )}
            </Content>
            <BottomNav />
        </>
    )       
}
