import { LOAD_REPAIRS_REQUEST, LOAD_REPAIRS_SUCCESS, LOAD_REPAIRS_FAILURE, SAVE_DOOR_REPAIR_REQUEST, SAVE_DOOR_REPAIR_SUCCESS, LOAD_REPAIR_HISTORY_REQUEST, LOAD_REPAIR_HISTORY_SUCCESS, LOAD_REPAIR_HISTORY_FAILURE, LATEST_SURVEY_REQUEST, LATEST_SURVEY_SUCCESS, LATEST_SURVEY_FAILURE, SAVE_DOOR_REPAIR_FAILURE, LOAD_ALL_REPAIRS_REQUEST, LOAD_ALL_REPAIRS_SUCCESS, SAVE_DOOR_REPAIR_RESET } from '../types/repairsTypes';

let repairs = null;
let allRepairs = null;
let doorsActionRequiredCount = 0;
let totalDoorsWithActionRequired = 0;

repairs = localStorage.getItem('repairs') ? JSON.parse(localStorage.getItem('repairs')) : null;
allRepairs = localStorage.getItem('allRepairs') ? JSON.parse(localStorage.getItem('allRepairs')) : null;
doorsActionRequiredCount = localStorage.getItem('doorsActionRequiredCount');
totalDoorsWithActionRequired = localStorage.getItem('totalDoorsWithActionRequired');

const initialState = {
    repairs,
    allRepairs,
    doorsActionRequiredCount: doorsActionRequiredCount || 0,
    totalDoorsWithActionRequired: totalDoorsWithActionRequired || 0,
    history: null,
    latestSurvey: null,
    loading: false,
    errors: null,
    saveLoading: false,
    historyLoading: false,
    historyErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REPAIRS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case LOAD_REPAIRS_SUCCESS:
            return {
                ...state,
                repairs: action.payload.repairs,
                doorsActionRequiredCount: action.payload.count,
                loading: false,
            }
        case LOAD_ALL_REPAIRS_REQUEST:
            return {
                ...state,
                loading: true,
                totalDoorsWithActionRequired: 0,
            }
        case LOAD_ALL_REPAIRS_SUCCESS:
            return {
                ...state,
                loading: false,
                allRepairs: action.payload.repairs,
                totalDoorsWithActionRequired: action.payload.count
            }
        case SAVE_DOOR_REPAIR_REQUEST:
            return {
                ...state,
                saveLoading: true,
            }
        case SAVE_DOOR_REPAIR_SUCCESS:
        case SAVE_DOOR_REPAIR_FAILURE:
            console.log(state.repairs);
            console.log(action.payload.surveyId);
            console.log(state.repairs[action.payload.surveyId]);
            return {
                ...state,
                saveLoading: false,
                repairs: {
                    ...state.repairs,
                    [action.payload.surveyId]: {
                        ...state.repairs[action.payload.surveyId],
                        repairs: [
                            ...state.repairs[action.payload.surveyId]?.repairs?.filter((repair) => repair?.appointment_id !== action.payload.appointmentId),
                        ]
                    }
                }
            }
        case SAVE_DOOR_REPAIR_RESET:
            return {
                ...state,
                saveLoading: false,
            }
        case LOAD_REPAIR_HISTORY_REQUEST:
            return {
                ...state,
                historyLoading: true,
                historyErrors: null,
                history: null,
            }
        case LOAD_REPAIR_HISTORY_SUCCESS:
            return {
                ...state,
                historyLoading: false,
                history: action.payload,
            }
        case LOAD_REPAIR_HISTORY_FAILURE:
            return {
                ...state,
                historyLoading: false,
                history: null,
                historyErrors: action.payload,
            }
        case LATEST_SURVEY_REQUEST:
            return {
                ...state,
                latestSurvey: null,
            }
        case LATEST_SURVEY_SUCCESS:
        case LATEST_SURVEY_FAILURE:
            return {
                ...state,
                latestSurvey: action.payload,
            }
        default:
            return state;
    }
};