import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import axios from 'axios';
import Localbase from 'localbase';
import Cookie from 'js-cookie';
import _ from 'lodash';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { FAILED_DOOR_PHOTO, SET_SURVEY_COMPLETE, UPDATE_SURVEY_KEY } from '../../types/surveyTypes';

import answers from '../../constants/answers';
import styles from './styles/SurveySummary.module.css';
import doorSections from '../../constants/doorSections';

export const SurveySummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [surveyResult, setSurveyResult] = useState(null);
    const [cost, setCost] = useState(0.00);
    const [totalPassedDoors, setTotalPassedDoors] = useState(0);
    const [totalFailedDoors, setTotalFailedDoors] = useState(0);
    const [totalPassed, setTotalPassed] = useState(0);
    const [totalFailed, setTotalFailed] = useState(0);
    const [totalCost, setTotalCost] = useState(0.00);
    const [adjustment, setAdjustment] = useState(0);
    const [filteredCosts, setFilteredCosts] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const surveySelector = useSelector((state) => state.survey);
    const { id, recordId, surveyId, includeCosts } = surveySelector;

    const doorSelector = useSelector((state) => state.door);
    const { doors } = doorSelector;
    
    const costsSelector = useSelector((state) => state.costs);
    const { costs } = costsSelector;

    const numOfDoors = _.values(doors).filter((door) => !door.deleted && door.leftId === null).length;

    async function uploadPhoto(document, doorId) {
        const fd = new FormData();
        const fieldName = document?.name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;

        fd.append(fieldName, document?.photo);

        await axios.post('https://hotel.napfis.co.uk/app_handler.php', fd, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data',
                'WG-Method': 'SAVE_DOOR_PHOTO',
                'Wg-Doorid': doorId,
                'Wg-Key': Cookie.get('accessToken')
            }
        });
    }

    const uploadDoorPhotos = async () => {
        const db = new Localbase('napfis-hotelapp');

        Object.keys(doors).forEach(async (doorId) => {

            if (!doors[doorId]?.deleted) {
                try {
                    const document1 = await db.collection('door-photos').doc({ id: doorId, name: 'photograph_1' }).get();
                    const document2 = await db.collection('door-photos').doc({ id: doorId, name: 'photograph_2' }).get();

                    if (document1) {
                        // await uploadPhoto(document1, doorId);
                        
                        if (doors[doorId]?.photographs?.['photograph_1'] !== document1?.filename) {
                            await uploadPhoto(document1, doorId);
                        }
                    }
    
                    if (document2) {
                        // await uploadPhoto(document2, doorId);
                        
                        if (doors[doorId]?.photographs?.['photograph_2'] !== document2?.filename) {
                            await uploadPhoto(document2, doorId);
                        }
                    }
                } catch (error) {
                    console.error(error);
                    dispatch({ type: FAILED_DOOR_PHOTO, payload: doorId });
                }
            }
        });
    }

    const saveSummary = async (tmpRecordId) => {
        const summary = {
            inspection_total: totalCost,
            inspection_adjustment: adjustment,
            inspection_initial_cost: cost,
            total_doors: numOfDoors,
            passed_doors: totalPassedDoors,
            failed_doors: totalFailedDoors,
        };

        const savedSummary = JSON.parse(localStorage.getItem('summary'));
        const tmpSummary = { ...savedSummary, [id]: summary };
        localStorage.setItem('summary', JSON.stringify(tmpSummary));

        try {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', summary, { headers: { 'Wg-Method': 'SAVE_SURVEY_SUMMARY', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': tmpRecordId, 'Wg-Surveyid': surveyId }})
        } catch (error) {
            console.error(error);
        }
    }

    const handleCompleteSurvey = async () => {
        setLoading(true);

        const clientData = JSON.parse(localStorage.getItem('clientData'));

        let tmpRecordId = 0;
        let promises = [];

        if (clientData) {
            const fd = new FormData();

            fd.append('hotel_name', clientData[id]?.hotel_name);
            fd.append('survey_name', clientData[id]?.survey_name);
            fd.append('survey_type', clientData[id]?.survey_type);
            fd.append('survey_frequency', clientData[id]?.survey_frequency);

            if (parseInt(recordId) === 0) {
                try {
                    const { data: surveyData } = await axios.post('https://hotel.napfis.co.uk/app_handler.php', fd, { headers: { 'Wg-Method': 'SAVE_SURVEY_FORM', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': recordId }});
                    
                    tmpRecordId = surveyData?.record_id;
        
                    if (surveyData?.survey_key) {
                        dispatch({ type: UPDATE_SURVEY_KEY, payload: surveyData?.survey_key });
                    }
                } catch (error) {
                    console.error(error)
                    setError('The survey wasn\'t fully uploaded. Please complete the survey when you have an Internet connection.');
                    setLoading(false);
                }
            } else {
                console.log('here 3');

                tmpRecordId = recordId;
            }
        }

        Object.keys(doors).forEach(async (doorId) => {
            const doorRecordId = doors[doorId]?.recordId;
            
            if (doors[doorId]) {
                promises.push(axios.post('https://hotel.napfis.co.uk/app_handler.php', doors[doorId], {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'WG-Surveyid': tmpRecordId,
                        'Wg-Doorid': doorRecordId,
                        'Wg-Method': 'SAVE_DOOR_V2',
                        'Wg-Key': Cookie.get('accessToken')
                    }
                }));
            }
        });
        
        // promises.push();

        axios.all(promises)
            .then(axios.spread((...args) => {
                uploadDoorPhotos();
                saveSummary(tmpRecordId);
                completeSurvey();
            }))
            .catch((error) => {
                setError('The survey wasn\'t fully uploaded. Please complete the survey when you have an Internet connection.');
                setLoading(false);
            });
    }

    const completeSurvey = () => {
        dispatch({ type: SET_SURVEY_COMPLETE });
        history.push('/survey/complete');
    };

    const handleBack = () => {
        history.push('/survey/doors');
    };

    useEffect(() => {
        const doorsFiltered = _.values(doors).filter(door => door.deleted === false && door.leftId === null).map((door) => {
            let tmpPassed = door.answers.reduce((acc, current) => {
                return current.answer === answers.PASSED ? acc + 1 : acc
            }, 0);
            let tmpFailed = door.answers.reduce((acc, current) => {
                return current.answer === answers.FAILED ? acc + 1 : acc
            }, 0);
            let tmpTotal = door.answers.reduce((acc, current) => {
                return current.answer === answers.FAILED ? acc + parseFloat(filteredCosts[current.id]?.[current?.failedOption]) : acc
            }, 0.00);
            let tmpOther = parseFloat(door?.other?.cost);

            const rightDoor = _.values(doors).filter(door => door.deleted === false).find((d) => d.leftId === door.id);

            if (rightDoor) {
                tmpPassed += rightDoor.answers.reduce((acc, current) => {
                    return current.answer === answers.PASSED ? acc + 1 : acc
                }, 0);
                tmpFailed += rightDoor.answers.reduce((acc, current) => {
                    return current.answer === answers.FAILED ? acc + 1 : acc
                }, 0);
                tmpTotal += rightDoor.answers.reduce((acc, current) => {
                    return current.answer === answers.FAILED ? acc + parseFloat(filteredCosts[current.id]?.[current?.failedOption]) : acc
                }, 0.00);
                tmpOther += parseFloat(rightDoor?.other?.cost);
            }

            return {
                id: door.id,
                ref: door.ref,
                passed: tmpPassed,
                failed: tmpFailed,
                total: tmpTotal,
                other: tmpOther
            }
        });

        // console.log(doorsFiltered);

        const doorResults = doorsFiltered.filter((door) => door !== null);

        setSurveyResult(doorResults.find((door) => door.failed > 0));
        setTotalPassedDoors(doorResults.reduce((acc, current) => current.failed === 0 ? acc + 1 : acc, 0));
        setTotalFailedDoors(doorResults.reduce((acc, current) => current.failed > 0 ? acc + 1 : acc, 0));
        setTotalPassed(doorResults.reduce((acc, current) => acc + current.passed, 0));
        setTotalFailed(doorResults.reduce((acc, current) => acc + current.failed, 0));
        const total = doorResults.reduce((acc, current) => acc + current.total, 0.00) + doorResults.reduce((acc, current) => acc + current.other, 0.00);
        
        setCost(total);
        setTotalCost(total);
        setAdjustment(0);
    }, [doors, filteredCosts]);

    useEffect(() => {
        if (adjustment) {
            setTotalCost(parseFloat((cost + ((cost / 100) * parseInt(adjustment)))).toFixed(2));
        }
    }, [adjustment, cost]);

    useEffect(() => {
        let tmp = {};

        if (costs) {
            Object.keys(costs).forEach((key) => tmp[key.slice(key.length - 3)] = costs[key]);
        }

        setFilteredCosts(tmp);
    }, [costs]);

    return (
        <Fragment>
            <Header>Survey Summary</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className="form__buttons">
                    <button className="form__button secondary" onClick={handleBack}>Back</button>
                </div>
                <div className={styles.surveyResultContainer}>
                    {surveyResult ? <Cross class={styles.failed} /> : <Check className={styles.passed} /> }
                </div>
                <div className={styles.surveyDoorsContainer}>
                    <p style={{ fontWeight: '600' }}>{!surveyResult ? 'Please review the cost summary below' : 'There are items that require attention. A summary can be found below.'}</p>
                    <div>No. of Doors: {numOfDoors}</div>
                    <div>No. of Passed Doors: {totalPassedDoors}</div>
                    <div>No. of Failed Doors: {totalFailedDoors}</div>
                    <div>No. of Passed Questions: {totalPassed}</div>
                    <div>No. of Failed Questions: {totalFailed}</div>
                </div>
                
                {includeCosts === '1' ? (
                    <div className={styles.surveyTotalContainer}>
                        <div className={styles.surveyTotalRow}>
                            <div>Cost:</div>
                            <div>£{cost}</div>
                        </div>
                        <div className={styles.surveyTotalRow}>
                            <div>Adjustment</div>
                            <div><input type="number" value={adjustment} onChange={(e) => setAdjustment(e.target.value)} /> %</div>
                        </div>
                        <div className={`${styles.surveyTotalRow} ${styles.total}`}>
                            <div>Total Cost:</div>
                            <div>£{totalCost}</div>
                        </div>
                    </div>
                ) : null}

                <p>Please ensure you've completed all necassary changes before you proceed. After you've completed the survey you cannot go back and make further changes.</p>
                    
                <div className="form__buttons">
                    <button className="form__button form__button--inline" disabled={loading} onClick={handleCompleteSurvey}>Complete Survey <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
                {error && (<div className="error" style={{ textAlign: 'center' }}>{error}</div>)}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
