import axios from 'axios';
import Cookie from 'js-cookie';
import Localbase from 'localbase';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import answers from '../constants/answers';
import doorSections from '../constants/doorSections';

import {
    ADD_DOOR_REQUEST,
    ADD_DOOR_SUCCESS,
    SAVE_DOOR_PHOTO_REQUEST,
    SAVE_DOOR_PHOTO_SUCCESS,
    SAVE_DOOR_OTHER_REQUEST,
    SAVE_DOOR_OTHER_SUCCESS,
    REMOVE_DOOR_PHOTO_REQUEST,
    REMOVE_DOOR_PHOTO_SUCCESS,
    SAVE_DOOR_REQUEST,
    SAVE_DOOR_SUCCESS,
    REMOVE_DOOR_REQUEST,
    REMOVE_DOOR_SUCCESS,
    RESTORE_DOOR_REQUEST,
    RESTORE_DOOR_SUCCESS,
    SAVE_DOOR_REF_REQUEST,
    SAVE_DOOR_REF_SUCCESS,
    SAVE_DOOR_TYPE_REQUEST,
    SAVE_DOOR_TYPE_SUCCESS,
    SAVE_SECTION_REQUEST, 
    SAVE_SECTION_SUCCESS,
    SAVE_TO_LOCALSTORAGE_SUCCESS,
    SAVE_TO_LOCALSTORAGE_REQUEST,
    SAVE_FAILED_OPTION_REQUEST,
    SAVE_FAILED_OPTION_SUCCESS,
    SAVE_FAILED_OTHER_REQUEST,
    SAVE_FAILED_OTHER_SUCCESS,
    LOAD_DOOR_REPAIRS_REQUEST,
    LOAD_DOOR_REPAIRS_SUCCESS,
    DOOR_REDIRECT,
    DUPLICATE_DOOR_REQUEST,
    DUPLICATE_DOOR_SUCCESS,
    SAVE_DOOR_PHOTO_FAILURE
} from '../types/doorTypes';

export const addDoor = (parentId = null, type = null, ref = '', redirect = false, redirectDirectory = '') => async (dispatch, getState) => {
    const state = getState();
    const { app: { sections }, survey: { recordId: surveyRecordId }, door: { doors } } = state;

    let doorSort = 0;

    if (doors) {
        doorSort = Object.keys(doors);
    }

    const id = uuidv4();
    
    dispatch({ type: ADD_DOOR_REQUEST });
    
    let data = { 
        id,
        parentId: 0,
        recordId: 0,
        ref: ref !== "" ? ref : id,
        leftId: parentId,
        type,
        answers: _.cloneDeep(sections),
        other: {
            text: '',
            cost: 0.00
        },
        photographs: {
            photograph_1: '',
            photograph_2: ''
        },
        sort: doorSort,
        deleted: false,
    };

    try {
        const { data: result } = await axios.post('https://hotel.napfis.co.uk/app_handler.php', data, { headers: { 'WG-Method': 'ADD_DOOR', 'Wg-Surveyid': surveyRecordId, 'WG-Key': Cookie.get('accessToken') }});

        if (result) {
            data.recordId = result.record_id;
        }

    } catch (error) {

    }

    dispatch({ type: ADD_DOOR_SUCCESS, payload: data });

    if (redirect) {
        dispatch({ type: DOOR_REDIRECT, payload: `${redirectDirectory}${id}` });
    }

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
};

export const duplicateDoor = (doorId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id, recordId } } = state;

    try {
        dispatch({ type: DUPLICATE_DOOR_REQUEST });

        const doorData = doors[doorId];

        if (doorData) {
            const { data } = await axios.post('https://hotel.napfis.co.uk/app_handler.php', doorData, { headers: { 'WG-Method': 'DUPLICATE_DOOR', 'Wg-Entryid': recordId, 'Wg-Duplicate': 10, 'WG-Key': Cookie.get('accessToken') }});

            dispatch({ type: DUPLICATE_DOOR_SUCCESS, payload: data?.doors });
            
            let doors = JSON.parse(localStorage.getItem('doors'));

            if (doors) {
                doors[id] = { ...doors[id], ...data?.doors };
                localStorage.setItem('doors', JSON.stringify(doors));
            } else {
                localStorage.setItem('doors', JSON.stringify({ [id]: data?.doors }));
            }

            const db = new Localbase('napfis-hotelapp');

            console.log(data?.doors);

            if (data?.doors) {
                if (Object.keys(data?.doors).length > 0) {
                    Object.keys(data?.doors).forEach(async (newDoorId) => {
                        const document1 = await db.collection('door-photos').doc({ id: doorId, name: 'photograph_1' }).get();
                        const document2 = await db.collection('door-photos').doc({ id: doorId, name: 'photograph_2' }).get();
            
                        if (document1) {
                            await db.collection('door-photos').add({ ...document1, id: newDoorId });
                        }     

                        if (document2) {
                            await db.collection('door-photos').add({ ...document2, id: newDoorId });
                        }       
                    })
                }
            }


            // if (document2) {
            //     if (doors[doorId]?.photographs?.['photograph_2'] !== document2?.filename) {
            //         await uploadPhoto(document2, doorId);
            //     }
            // }
        }
    } catch (error) {

    }
};

export const saveDoor = (doorId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { recordId: surveyRecordId } } = state;

    dispatch({ type: SAVE_DOOR_REQUEST });
    
    let data = doors[doorId] || [];

    try {
        const { data: result } = await axios.post('https://hotel.napfis.co.uk/app_handler.php', data, { headers: { 'WG-Method': 'SAVE_DOOR_V2', 'Wg-Surveyid': surveyRecordId, 'Wg-Doorid': doorId, 'WG-Key': Cookie.get('accessToken') }});

        if (result && data.recordId === 0) {
            data.recordId = result.record_id;
        }

    } catch (error) {

    }

    dispatch({ type: SAVE_DOOR_SUCCESS, payload: data });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [surveyId]: { ...doors, [id]: data } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
};

export const saveSection = (doorId, sectionId, answer) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    console.log(doorId)
    console.log(sectionId)
    console.log(answer)

    dispatch({ type: SAVE_SECTION_REQUEST });

    const currentDoor = doors[doorId];

    let answeredSection = currentDoor && currentDoor.answers.find((answer) => answer.id === sectionId);
    answeredSection.answer = answer;
    if (answer === answers.PASSED) {
        answeredSection.failedOption = answers.FAILED_OPTION_NOT_ANSWERED;
    }

    const data = { id: doorId, answers: [...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection] };

    console.log(currentDoor);
    console.log(answeredSection);
    console.log(data);
    console.log([...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection])

    try {
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', answeredSection, { headers: { 'WG-Method': 'SAVE_DOOR_SECTION', 'Wg-Doorid': currentDoor.recordId, 'Wg-Sectionid': sectionId, 'WG-Key': Cookie.get('accessToken') }});

    } catch (error) {

    }

    dispatch({ type: SAVE_SECTION_SUCCESS, payload: data });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], ...data } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveFailedOption = (doorId, sectionId, option) => async (dispatch, getState) => {
    dispatch({ type: SAVE_FAILED_OPTION_REQUEST });

    const state = getState();
    const { survey: { surveyId }, door: { doors } } = state;

    const currentDoor = doors[doorId];

    let answeredSection = currentDoor && currentDoor.answers.find((answer) => answer.id === sectionId);
    answeredSection.failedOption = option;

    const data = { id: doorId, answers: [...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection] };

    try {
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', answeredSection, { headers: { 'WG-Method': 'SAVE_FAILED_OPTION', 'Wg-Surveyid': surveyId, 'Wg-Doorid': currentDoor.recordId, 'Wg-Sectionid': sectionId, 'WG-Key': Cookie.get('accessToken') }});

    } catch (error) {

    }

    dispatch({ type: SAVE_FAILED_OPTION_SUCCESS, payload: data });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveFailedOther = (doorId, sectionId, other, urgent) => async (dispatch, getState) => {
    dispatch({ type: SAVE_FAILED_OTHER_REQUEST });

    const state = getState();
    const { door: { doors } } = state;

    const currentDoor = doors[doorId];

    let answeredSection = currentDoor && currentDoor.answers.find((answer) => answer.id === sectionId);
    answeredSection.failedOther = other;
    answeredSection.urgent = urgent;

    const data = { id: doorId, answers: [...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection] };

    try {
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', answeredSection, { headers: { 'WG-Method': 'SAVE_FAILED_OTHER', 'Wg-Doorid': currentDoor.recordId, 'Wg-Sectionid': sectionId, 'WG-Key': Cookie.get('accessToken') }});

    } catch (error) {

    }

    dispatch({ type: SAVE_FAILED_OTHER_SUCCESS, payload: data });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveRef = (doorId, ref) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: SAVE_DOOR_REF_REQUEST });

    if (currentDoor?.recordId) {
        try {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', { ref: ref }, { headers: { 'WG-Method': 'SAVE_DOOR_REF', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: SAVE_DOOR_REF_SUCCESS, payload: { id: doorId, ref } });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveType = (doorId, type) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: SAVE_DOOR_TYPE_REQUEST });
    
    if (currentDoor?.recordId) {
        try {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', { type: type }, { headers: { 'WG-Method': 'SAVE_DOOR_TYPE', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: SAVE_DOOR_TYPE_SUCCESS, payload: { id: doorId, type } });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], type } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveOther = (doorId, otherText, otherCost) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: SAVE_DOOR_OTHER_REQUEST });
    
    if (currentDoor?.recordId) {
        try {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', { text: otherText, cost: otherCost }, { headers: { 'WG-Method': 'SAVE_DOOR_OTHER', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }
    
    const other = {
        text: otherText,
        cost: otherCost
    };

    dispatch({ type: SAVE_DOOR_OTHER_SUCCESS, payload: { id: doorId, other } });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], other } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const savePhoto = (doorId, formData, type, name) => async (dispatch) => {
    dispatch({ type: SAVE_DOOR_PHOTO_REQUEST });
    
    try {
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'WG-Method': 'SAVE_DOOR_PHOTO',
                'Wg-Doorid': doorId,
                'WG-Key': Cookie.get('accessToken')
            }
        });

        dispatch({ type: SAVE_DOOR_PHOTO_SUCCESS, payload: { id: doorId, name: type, value: name } });
    } catch (error) {
        dispatch({ type: SAVE_DOOR_PHOTO_FAILURE });
    }
}

export const removePhoto = (doorId, fieldId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const recordId = doors[doorId]?.recordId;

    const type = doorSections.PHOTOGRAPH_ONE === parseInt(fieldId) ? 'photograph_1' : 'photograph_2';

    dispatch({ type: REMOVE_DOOR_PHOTO_REQUEST });
    
    if (recordId) {
        try {
            await axios.delete('https://hotel.napfis.co.uk/app_handler.php', {
                headers: {
                    'WG-Method': 'REMOVE_DOOR_PHOTO',
                    'Wg-Doorid': recordId,
                    'Wg-Fieldid': fieldId,
                    'WG-Key': Cookie.get('accessToken')
                }
            });

            dispatch({ type: REMOVE_DOOR_PHOTO_SUCCESS, payload: { id: doorId, name: type } });
        } catch (error) {
    
        }
    }
}

export const removeDoor = (doorId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: REMOVE_DOOR_REQUEST });

    if (currentDoor?.recordId) {
        try {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', {}, { headers: { 'WG-Method': 'REMOVE_DOOR', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    const rightDoor = _.values(doors).find((door) => door.leftId === doorId);

    dispatch({ type: REMOVE_DOOR_SUCCESS, payload: { doorId, rightDoor } });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const loadRepairs = (surveyId, id) => async dispatch => {
    try {
        dispatch({ type: LOAD_DOOR_REPAIRS_REQUEST });

        const { data } = await axios.get('https://hotel.napfis.co.uk/app_handler.php', { headers: { 'WG-Method': 'GET_DOOR_REPAIRS', 'Wg-SurveyId': surveyId, 'Wg-EntryId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_DOOR_REPAIRS_SUCCESS, payload: data });
    } catch (error) {
    }
};

export const restoreDoor = (doorId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: RESTORE_DOOR_REQUEST });

    if (currentDoor?.recordId) {
        try {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', {}, { headers: { 'WG-Method': 'RESTORE_DOOR', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: RESTORE_DOOR_SUCCESS, payload: doorId });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], deleted: false } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveDoorToLocalStorage = () => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const savedDoors = JSON.parse(localStorage.getItem('doors'));
    const tmpDoors = { ...savedDoors, [id]: { ...doors } };
    localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_SUCCESS });
}

// export const saveDoor = (doorId) => async dispatch => {
//     const db = new Localbase('saved-uploads');

//     try {
//         dispatch({ type: SAVE_DOOR_REQUEST });

//         const fd = new FormData();

//         for (const [key, value] of Object.entries(formData)) {
//             if (value.type === 'file') {
//                 if (typeof value.value === 'object') {
//                     fd.append(key, value.value, value.value.name);
                    
//                     db.collection(`door-images-${activeSurvey}`).add({ key, file: value.value }, `${id}_${key}`);
//                 } else {
//                     fd.append(key, value.value);
//                 }
//             } else {
//                 fd.append(key, value.value);

//                 console.log(value.value);

//                 if (_.includes(requiredFields, key) && value.value !== "") {
//                     completedFields++;
//                 }
//             }
//         }

//         if (doorCosts) {
//             if (doorCosts[activeSurvey]) {
//                 if (doorCosts[activeSurvey][id]) {
//                     doorCosts[activeSurvey][id] = costs;
//                     localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
//                 } else {
//                     doorCosts[activeSurvey] = { ...doorCosts[activeSurvey], [id]: costs };
//                     localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
//                 }
//             } else {
//                 doorCosts = { [activeSurvey]: { [id]: costs }};
//                 localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
//             }
//         } else {
//             const newDoorCosts = { [id]: costs };

//             localStorage.setItem('doorCosts', JSON.stringify({ [activeSurvey]: newDoorCosts }));
//         }

//         let doorScores = JSON.parse(localStorage.getItem('doorScores'));
        
//         if (doorScores) {
//             if (doorScores[activeSurvey]) {
//                 if (doorScores[activeSurvey][id]) {
//                     doorScores[activeSurvey][id] = scores;
//                     localStorage.setItem('doorScores', JSON.stringify(doorScores));
//                 } else {
//                     doorScores[activeSurvey] = { ...doorScores[activeSurvey], [id]: scores };
//                     localStorage.setItem('doorScores', JSON.stringify(doorScores));
//                 }
//             } else {
//                 doorScores = { [activeSurvey]: { [id]: scores }};
//                 localStorage.setItem('doorScores', JSON.stringify(doorScores));
//             }
//         } else {
//             const newDoorScores = { [id]: scores };
//             localStorage.setItem('doorScores', JSON.stringify({ [activeSurvey]: newDoorScores }));
//         }

//         const survey = JSON.parse(localStorage.getItem('survey'));

//         let doors = {};
//         if (survey[activeSurvey]) {
//             doors = survey[activeSurvey]?.doors;
//         }
    
//         const { data } = await axios.post('https://hotel.napfis.co.uk/app_handler.php', fd, {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'WG-EntryId': doors[id]?.doorId || id,
//                 'WG-RecordId': surveyId,
//                 'Wg-Method': 'SAVE_DOOR',
//                 'Wg-Key': Cookie.get('accessToken')
//             }
//         });

//         doorId = data?.form_entry_id;
//         surveyId = data?.survey_id;

//         if (data?.fields) {
//             const db = new Localbase('saved-uploads');
            
//             for (const [key, value] of Object.entries(formData)) {
//                 if (data?.fields[key]) {
//                     savedFormData[key] = { type: value.type, value: data?.fields[key] };

//                     try {

//                         const document = await db.collection(`door-images-${activeSurvey}`).doc(`${id}_${key}`).get();

//                         if (document) {
//                             await db.collection(`door-images-${activeSurvey}`).doc(`${id}_${key}`).delete();
//                         }

//                     } catch (error) {
//                         console.error(error);
//                     }
//                 }
//             }
//         }

//         doorToSave = { id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

//         dispatch({ type: SAVE_DOOR_SUCCESS, payload: { door: doorToSave, recordId: surveyId }});

//     } catch (error) {
//         doorToSave = { id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

//         dispatch({ type: SAVE_DOOR_FAILURE, payload: { door: doorToSave, recordId: surveyId }});
//     }

//     let survey = _.cloneDeep(JSON.parse(localStorage.getItem('survey')));
    
//     if (survey[activeSurvey]) {

//         survey[activeSurvey].recordId = surveyId;

//         localStorage.setItem('survey', JSON.stringify(survey));
        

//         survey[activeSurvey].recordId = surveyId;
    
//         if (survey[activeSurvey].doors[id]) {
//             survey[activeSurvey].doors[id] = { ...survey[activeSurvey].doors[id], id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };
//         }
    
//         localStorage.setItem('survey', JSON.stringify(survey));
//     }

// };