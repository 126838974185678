import _ from 'lodash';

import { LOAD_SURVEY_REQUEST, LOAD_SURVEY_SUCCESS, LOAD_SURVEY_FAILURE, CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAILURE, SAVE_DOOR_REQUEST, SAVE_DOOR_SUCCESS, SAVE_DOOR_FAILURE, LOAD_DOOR_REQUEST, LOAD_DOOR_SUCCESS, LOAD_DOOR_FAILURE, ADD_DOOR_REQUEST, ADD_DOOR_SUCCESS, ADD_DOOR_FAILURE, REMOVE_DOOR_REQUEST, REMOVE_DOOR_SUCCESS, REMOVE_DOOR_FAILURE, UPDATE_SURVEY_REQUEST, UPDATE_SURVEY_SUCCESS, UPDATE_SURVEY_FAILURE, CLEAR_DOOR_PROCEED, SET_SURVEY_COMPLETE, CLEAR_SURVEY, UPDATE_SURVEY_KEY, CLEAR_DOORLIST_PROCEED, UPDATE_TOTAL_FIELDS, SET_ACTIVE_PLAN_REQUEST, SET_ACTIVE_PLAN_SUCCESS, SET_ACTIVE_PLAN_FAILURE, SAVE_DOOR_REF_REQUEST, SAVE_DOOR_REF_SUCCESS, SAVE_DOOR_REF_FAILURE, CLEAR_PDF_SELECTOR, REMOVE_ALL_DOORS_REQUEST, REMOVE_ALL_DOORS_SUCCESS, REMOVE_ALL_DOORS_FAILURE, SET_PDF_SELECTOR_TRUE, CREATE_SURVEY_ENTRY_SUCCESS, CREATE_SURVEY_ENTRY_REQUEST, POPULATE_SURVEY_DOORS, FAILED_DOOR_PHOTO, REMOVE_FAILED_DOOR_PHOTO, CLEAR_FAILED_DOOR_PHOTOS, CREATE_SURVEY_ENTRY_FAILURE, CLEAR_REDIRECT, SET_CLIENT_DATA } from "../types/surveyTypes";

const surveyId = localStorage.getItem('activeSurvey');
const clientDataDisplay = localStorage.getItem('clientDataDisplay') ? JSON.parse(localStorage.getItem('clientDataDisplay')) : '';

let survey = null;

if (surveyId) {
    const surveyStorage = JSON.parse(localStorage.getItem('survey'));

    if (surveyStorage) {
        if (surveyStorage[surveyId]) {
            survey = surveyStorage[surveyId];
        } else {
            survey = null;
        }
    } else {
        survey = null;
    }
}

const initialState = {
    id: survey?.id || '',
    surveyId: survey?.surveyId || 0,
    userId: survey?.userId || 0,
    recordId: survey?.recordId || 0,
    surveyKey: survey?.surveyKey || '',
    offline: true,
    completed: false,
    hotelName: clientDataDisplay?.hotel_name || '',
    surveyName: clientDataDisplay?.survey_name || '',
    surveyType: clientDataDisplay?.survey_type || '',
    frequency: clientDataDisplay?.survey_frequency || '',
    includeCosts: clientDataDisplay?.survey_include_costs || '',
    redirect: null,
    entryRedirect: null,
    doorForm: null,
    failedDoorPhotos: [],
    doorFormProceed: false,
    doorListProceed: false,
    surveyLoading: false,
    surveyErrors: null,
    doorFormLoading: false,
    doorFormErrors: null,
    doorLoading: false,
    doorErrors: null,
    doorListLoading: false,
    doorListErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SURVEY_REQUEST:
            return {
                ...state,
                id: '',
                surveyId: 0,
                userId: 0,
                recordId: 0,
                surveyKey: '',
                offline: true,
                completed: false,
                redirect: null,
                doorForm: null,
                doorFormProceed: false,
                doorListProceed: false,
                surveyLoading: true,
                surveyErrors: null,
                doorFormLoading: false,
                doorFormErrors: null,
                doorLoading: false,
                doorErrors: null,
                doorListLoading: false,
                doorListErrors: null,
            }
        case LOAD_SURVEY_SUCCESS:
            return {
                ...state,
                surveyLoading: false,
                ...action.payload,
            }
        case LOAD_SURVEY_FAILURE:
            return {
                ...state,
                surveyLoading: false,
            }
        case CREATE_SURVEY_REQUEST:
        case CREATE_SURVEY_ENTRY_REQUEST:
            return {
                ...state,
                id: '',
                surveyId: 0,
                userId: 0,
                recordId: 0,
                surveyKey: '',
                entryRedirect: null
            }
        case CREATE_SURVEY_SUCCESS:
            return {
                ...state,
                surveyLoading: false,
                id: action.payload.id,
                userId: action.payload.userId,
                recordId: action.payload.recordId,
                surveyKey: action.payload.surveyKey,
                hotelName: action.payload?.data?.hotel_name,
                surveyName: action.payload?.data?.survey_name,
                surveyType: action.payload?.data?.survey_type,
                frequency: action.payload?.data?.survey_frequency,
                includeCosts: action.payload?.data?.survey_include_costs,
                doorListProceed: true,
            }
        case CREATE_SURVEY_FAILURE:
            return {
                ...state,
                id: action.payload.id,
                userId: action.payload?.userId,
                surveyLoading: false,
                offline: true,
                surveyErrors: action.payload.errors,
                doorListProceed: true,
            }
        case UPDATE_SURVEY_REQUEST:
            return {
                ...state,
                surveyLoading: true,
                surveyErrors: null,
                doorListProceed: false,
            }
        case UPDATE_SURVEY_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                surveyId: action.payload.surveyId,
                userId: action.payload.userId,
                recordId: action.payload.recordId,
                surveyKey: action.payload.surveyKey,
                surveyLoading: false,
                surveyErrors: null,
                doorListProceed: true,
            }
        case CREATE_SURVEY_ENTRY_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                surveyId: action.payload.surveyId,
                userId: action.payload.userId,
                recordId: action.payload.recordId,
                surveyKey: action.payload.surveyKey,
                hotelName: action.payload?.clientData?.hotel_name,
                surveyName: action.payload?.clientData?.survey_name,
                surveyType: action.payload?.clientData?.survey_type,
                frequency: action.payload?.clientData?.survey_frequency,
                includeCosts: action.payload?.clientData?.survey_include_costs,
            }
        case CREATE_SURVEY_ENTRY_FAILURE:
            return {
                ...state,
                entryRedirect: action.payload,
            }
        
        case SAVE_DOOR_REQUEST:
            return {
                ...state,
                doorLoading: true,
                doorErrors: null
            }
        case SAVE_DOOR_SUCCESS:
        case SAVE_DOOR_FAILURE:
            return {
                ...state,
                doorLoading: false,
                doorFormProceed: true,
                recordId: action.payload.recordId,
            }
        case LOAD_DOOR_REQUEST:
            return {
                ...state,
                doorForm: null,
                doorFormLoading: true,
                doorFormErrors: null
            }
        case LOAD_DOOR_SUCCESS:
            return {
                ...state,
                doorFormLoading: false,
                doorForm: action.payload
            }
        case LOAD_DOOR_FAILURE:
            return {
                ...state,
                doorFormLoading: false,
                doorForm: action.payload
            }
        case ADD_DOOR_REQUEST:
            return {
                ...state,
                redirect: null,
                doorListLoading: true,
                doorListErrors: null
            }
        case ADD_DOOR_SUCCESS:
        case ADD_DOOR_FAILURE:
            return {
                ...state,
                doorListLoading: false,
                redirect: action.payload.id,
            }
        case REMOVE_DOOR_REQUEST:
            return {
                ...state,
                doorListLoading: true
            }
        case REMOVE_DOOR_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
            }
        case REMOVE_DOOR_FAILURE:
            return {
                ...state,
                doorListLoading: false
            }
        case REMOVE_ALL_DOORS_REQUEST:
            return {
                ...state,
                doorListLoading: true
            }
        case REMOVE_ALL_DOORS_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
            }
        case REMOVE_ALL_DOORS_FAILURE:
            return {
                ...state,
                doorListLoading: false
            }
        case CLEAR_DOOR_PROCEED:
            return {
                ...state,
                doorFormProceed: false
            }
        case SET_SURVEY_COMPLETE:
            return {
                ...state,
                completed: true
            }
        case CLEAR_SURVEY:
            return {
                ...state,
                id: 0,
                recordId: 0,
                surveyKey: '',
                offline: true,
                completed: false,
                doorForm: null,
                doorFormProceed: false,
                surveyLoading: false,
                surveyErrors: null,
                doorFormLoading: false,
                doorFormErrors: null,
                doorLoading: false,
                doorErrors: null,
                doorListLoading: false,
                doorListErrors: null,
                proceedToPDFSelector: false,
            }
        case UPDATE_SURVEY_KEY:
            return {
                ...state,
                surveyKey: action.payload
            }
        case CLEAR_DOORLIST_PROCEED:
            return {
                ...state,
                doorListProceed: false
            }
        case UPDATE_TOTAL_FIELDS:
            return {
                ...state,
                totalFields: action.payload
            }
        case SET_ACTIVE_PLAN_REQUEST:
            return {
                ...state,
                doorListLoading: true,
            }
        case SET_ACTIVE_PLAN_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
                activePlan: action.payload,
            }
        case SET_ACTIVE_PLAN_FAILURE:
            return {
                ...state,
                doorListLoading: false,
            }
        case SAVE_DOOR_REF_REQUEST:
            return {
                ...state,
            }
        case SAVE_DOOR_REF_SUCCESS:
            return {
                ...state,
            }
        case SAVE_DOOR_REF_FAILURE:
            return {
                ...state,
            }
        case CLEAR_PDF_SELECTOR:
            return {
                ...state,
                proceedToPDFSelector: false
            }
        case SET_PDF_SELECTOR_TRUE:
            return {
                ...state,
                proceedToPDFSelector: true,
                activePlan: null,
            }
        case POPULATE_SURVEY_DOORS:
            return {
                ...state,
            }
        case FAILED_DOOR_PHOTO:
            return {
                ...state,
                failedDoorPhotos: [...state.failedDoorPhotos, action.payload],
            }
        case REMOVE_FAILED_DOOR_PHOTO:
            return {
                ...state,
                failedDoorPhotos: [...state.failedDoorPhotos.filter((door) => door !== action.payload)],
            }
        case CLEAR_FAILED_DOOR_PHOTOS:
            return {
                ...state,
                failedDoorPhotos: [],
            }
        case CLEAR_REDIRECT: 
            return {
                ...state,
                redirect: null,
            }
        case SET_CLIENT_DATA:
            return {
                ...state,
                hotelName: action.payload?.client_data?.hotel_name,
                surveyName: action.payload?.client_data?.survey_name,
                surveyType: action.payload?.client_data?.survey_type,
                frequency: action.payload?.client_data?.survey_frequency,
                includeCosts: action.payload?.client_data?.survey_include_costs,
            }
        default:
            return state;
    }
};