import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faFilePdf,
    faTimes,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import LoadingSkeleton from '../../components/LoadingSkeleton';

import {
    loadRecentSurveys,
    loadSurveyDashboard,
    loadSurveysDue,
    removeSurvey,
    removeSurveyEntry
} from '../../actions/surveyDashboardActions';
import { createSurveyEntry, loadSurvey } from '../../actions/surveyActions';
import { latestSurvey, loadRepairs } from '../../actions/repairsActions';
import { CLEAR_REDIRECT } from '../../types/surveyTypes';

import { ReactComponent as OpenDoor } from '../../assets/open_door.svg';

import styles from './SurveyDashboard.module.css';

export default function SurveyDashboard() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const [surveyToDelete, setSurveyToDelete] = useState(null);
    const [surveyEntryToDelete, setSurveyEntryToDelete] = useState(null);

    const surveyDashboardSelector = useSelector(
        (state) => state.surveyDashboard
    );
    const {
        hotelName,
        surveyName,
        repairsCount,
        surveysDue,
        loading,
        recentSurveysLoading,
        recentSurveys
    } = surveyDashboardSelector;
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    const savedSurveys = JSON.parse(localStorage.getItem('survey')) || {};

    const handleStartNewSurvey = () => {
        dispatch(createSurveyEntry(id));
        dispatch(loadRepairs(id));

        history.push('/survey/doors');
    };

    const handleContinueSurvey = (surveyId) => {
        dispatch(loadSurvey(surveyId));

        history.push('/survey/doors');
    };

    const handleRemoveSurveyEntryConfirmation = (recordId) => {
        setSurveyToDelete(null);
        setSurveyEntryToDelete(recordId);
    };

    const handleRemoveSurveyConfirmation = () => {
        setSurveyEntryToDelete(null);
        setSurveyToDelete(id);
    };

    const handleRemoveSurveyEntry = () => {
        dispatch(removeSurveyEntry(surveyEntryToDelete));
        dispatch(loadRecentSurveys(id));

        setSurveyEntryToDelete(null);
    };

    const handleRemoveSurvey = () => {
        dispatch(removeSurvey(id));

        history.push('/');
    };

    useEffect(() => {
        dispatch(loadSurveyDashboard(id));
        dispatch(loadRecentSurveys(id));
        dispatch(loadSurveysDue(id));
        dispatch(latestSurvey(id));
        dispatch({ type: CLEAR_REDIRECT });
    }, [id, dispatch]);

    return (
        <Fragment>
            <Header>
                {hotelName}: {surveyName}
            </Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }}>
                <div className={styles.dashboardHeader}>
                    <div>
                        <button
                            className={styles.newSurveyButton}
                            onClick={handleStartNewSurvey}
                        >
                            Start New Survey
                        </button>
                        <button
                            className={styles.previousSurveyButton}
                            onClick={() =>
                                history.push(`/survey/${id}/repairs`)
                            }
                        >
                            View Outstanding Repairs
                        </button>
                    </div>
                    <div>
                        <button
                            className={styles.deleteSurveyButton}
                            onClick={handleRemoveSurveyConfirmation}
                        >
                            Delete Survey
                        </button>
                    </div>
                </div>
                {!loading && repairsCount > 0 && (
                    <div className={styles.repairsStatus}>
                        There are currently {repairsCount} outstanding repairs
                        for {surveyName}.{' '}
                        <Link to={`/survey/${id}/repairs`}>VIEW NOW</Link>
                    </div>
                )}
                {loading ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        <div>
                            <div className={styles.surveysDueHeader}>
                                <h2 className={styles.surveyHeading}>
                                    Surveys Due
                                </h2>
                            </div>
                            <div className={styles.recentSurveyHeadings}>
                                <div className={styles.firstColumn}>Name</div>
                                <div className={styles.surveyType}>Type</div>
                                <div className={styles.surveyDoors}>
                                    <OpenDoor style={{ width: '14px' }} />
                                </div>
                                <div className={styles.surveyFailed}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color="#CD2121"
                                    />
                                </div>
                                <div className={styles.surveyPassed}>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color="#4BC33E"
                                    />
                                </div>
                                <div className={styles.surveyStatus}>
                                    Status
                                </div>
                                <div className={styles.actions}></div>
                            </div>
                            <div>
                                {surveysDue.map((survey) => (
                                    <div
                                        key={survey?.record_id}
                                        className={styles.surveyRow}
                                    >
                                        <div className={styles.firstColumn}>
                                            {survey?.hotel_name} -{' '}
                                            {survey?.survey_name}
                                        </div>
                                        <div className={styles.surveyType}>
                                            {survey?.survey_type}
                                        </div>
                                        <div className={styles.surveyDoors}>
                                            {survey?.total_doors}
                                        </div>
                                        <div className={styles.surveyFailed}>
                                            {survey?.failed_doors}
                                        </div>
                                        <div className={styles.surveyPassed}>
                                            {survey?.passed_doors}
                                        </div>
                                        <div className={styles.surveyStatus}>
                                            {survey?.due_status}
                                        </div>
                                        <div className={styles.actions}>
                                            <button
                                                className={styles.viewButton}
                                                onClick={handleStartNewSurvey}
                                            >
                                                Start
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
                {recentSurveysLoading ? (
                    <LoadingSkeleton />
                ) : (
                    <div className={styles.recentSurveysWrapper}>
                        <div className={styles.recentSurveysHeader}>
                            <h2 className={styles.surveyHeading}>
                                Previous Surveys
                            </h2>
                        </div>
                        <div className={styles.recentSurveyHeadings}>
                            <div className={styles.dateColumn}>Date</div>
                            <div className={styles.surveyType}>Type</div>
                            <div className={styles.surveyDoors}>
                                <OpenDoor style={{ width: '14px' }} />
                            </div>
                            <div className={styles.surveyFailed}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    color="#CD2121"
                                />
                            </div>
                            <div className={styles.surveyPassed}>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color="#4BC33E"
                                />
                            </div>
                            <div className={styles.surveyStatus}>Status</div>
                            <div className={styles.previousActions}></div>
                        </div>
                        <div className={styles.recentSurveysContainer}>
                            {!recentSurveysLoading ? (
                                recentSurveys.length > 0 ? (
                                    recentSurveys.map((survey) => {
                                        const surveyByKey = _.values(
                                            savedSurveys
                                        ).find(
                                            (s) =>
                                                s.surveyKey ===
                                                survey.survey_secret_key
                                        );

                                        return (
                                            <div
                                                key={survey?.record_id}
                                                className={styles.surveyRow}
                                            >
                                                <div
                                                    className={
                                                        styles.dateColumn
                                                    }
                                                    style={{
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    {survey?.plugin_updated}
                                                </div>
                                                <div
                                                    className={
                                                        styles.surveyType
                                                    }
                                                >
                                                    {survey?.survey_type}
                                                </div>
                                                <div
                                                    className={
                                                        styles.surveyDoors
                                                    }
                                                >
                                                    {survey?.total_doors}
                                                </div>
                                                <div
                                                    className={
                                                        styles.surveyFailed
                                                    }
                                                >
                                                    {survey?.failed_doors}
                                                </div>
                                                <div
                                                    className={
                                                        styles.surveyPassed
                                                    }
                                                >
                                                    {survey?.passed_doors}
                                                </div>
                                                <div
                                                    className={
                                                        styles.surveyStatus
                                                    }
                                                >
                                                    {survey?.survey_status}
                                                </div>
                                                <div
                                                    className={
                                                        styles.previousActions
                                                    }
                                                >
                                                    {survey?.survey_status ===
                                                        'In Progress' &&
                                                        surveyByKey && (
                                                            <button
                                                                className={
                                                                    styles.editRowButton
                                                                }
                                                                onClick={() =>
                                                                    handleContinueSurvey(
                                                                        survey?.survey_secret_key
                                                                    )
                                                                }
                                                            >
                                                                Continue
                                                            </button>
                                                        )}
                                                    {survey?.survey_status ===
                                                        'Completed' && (
                                                        <a
                                                            className={
                                                                styles.editRowButton
                                                            }
                                                            href={`https://www.napfis.co.uk/survey/hotel-v1/?${survey?.survey_secret_key}&${user?.secret_code}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            PDF{' '}
                                                            <FontAwesomeIcon
                                                                icon={faFilePdf}
                                                            />
                                                        </a>
                                                    )}
                                                    {survey?.record_id &&
                                                        survey?.parent_id && (
                                                            <button
                                                                className={
                                                                    styles.deleteSurveyEntryButton
                                                                }
                                                                onClick={() =>
                                                                    handleRemoveSurveyEntryConfirmation(
                                                                        survey?.record_id
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faTrashAlt
                                                                    }
                                                                    color="#ffffff"
                                                                />
                                                            </button>
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>No Previous Surveys</div>
                                )
                            ) : (
                                <div>Loading</div>
                            )}
                        </div>
                    </div>
                )}
                {surveyEntryToDelete && (
                    <div class="popup">
                        <p className="title">
                            Are you sure you want to delete this survey entry?
                        </p>
                        <div className="form__buttons">
                            <button
                                onClick={() => setSurveyEntryToDelete(null)}
                                className="form__button secondary"
                            >
                                No
                            </button>
                            <button
                                onClick={handleRemoveSurveyEntry}
                                className="form__button primary"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                )}
                {surveyToDelete && (
                    <div class="popup">
                        <p className="title">
                            Are you sure you want to delete this survey?
                        </p>
                        <div className="form__buttons">
                            <button
                                onClick={() => setSurveyToDelete(null)}
                                className="form__button secondary"
                            >
                                No
                            </button>
                            <button
                                onClick={handleRemoveSurvey}
                                className="form__button primary"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    );
}
