import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Dashboard from './pages/Dashboard';
import { DoorInspection } from './pages/DoorInspection';
import { InspectionForm } from './components/InspectionForm';
import { Costs } from './pages/Costs';
import { DoorList } from './components/DoorList';
import { DoorView } from './components/DoorView';
import { SurveySummary } from './components/SurveySummary';
import { SurveyComplete } from './components/SurveyComplete';
import { Help } from './pages/Help';
import { DataManagement } from './pages/DataManagement';
import PhotoManagement from './pages/PhotoManagement';
import NewSurvey from './pages/NewSurvey';
import SurveyDashboard from './pages/SurveyDashboard';
import AllSurveys from './pages/AllSurveys';
import AllRepairs from './pages/AllRepairs';
import SurveyRepairs from './pages/SurveyRepairs';
import RepairHistory from './pages/RepairHistory';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ProtectedRoute from './components/routes/ProtectedRoute';

import { getCosts } from './actions/costsActions';
import { getDependencies } from './actions/appActions';

import './App.css';

export const App = () => {
  const dispatch = useDispatch();

  const authSelector = useSelector((state) => state.auth);
  const { user, isAuthenticated } = authSelector;
  const appSelector = useSelector((state) => state.app);
  const { showBackground } = appSelector;

  useEffect(() => {
    if (user) {
      dispatch(getDependencies());
      dispatch(getCosts());
    }
  }, [dispatch, user]);

  return (
    <Router>
      <div className={`app${window.location.pathname === '/login' ? ' no-transition' : ''}${showBackground ? ' popup-background' : ''}`}>
        <Switch>
          <ProtectedRoute path="/" exact component={Dashboard} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey" exact component={DoorInspection} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/new" isAuthenticated={isAuthenticated}>
            <NewSurvey />
          </ProtectedRoute>
          <ProtectedRoute path="/survey/edit" isAuthenticated={isAuthenticated}>
            <InspectionForm showEdit={true} />
          </ProtectedRoute>
          <ProtectedRoute path="/survey/doors" exact component={DoorList} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/doors/:id" component={DoorView} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/summary" component={SurveySummary} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/complete" component={SurveyComplete} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/all" component={AllSurveys} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/:id" exact component={SurveyDashboard} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/:id/repairs" component={SurveyRepairs} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/survey/:id/repair-history" component={RepairHistory} isAuthenticated={isAuthenticated} />
          <ProtectedRoute path="/repairs" component={AllRepairs} isAuthenticated={isAuthenticated} />
          {/* <ProtectedRoute path="/survey/floorplan" component={PDFImageSelector} isAuthenticated={isAuthenticated} /> */}
          <ProtectedRoute path="/costs" component={Costs} isAuthenticated={isAuthenticated} />
          <Route path="/help" component={Help} />
          <Route path="/data" component={DataManagement} />
          <Route path="/photo-management" component={PhotoManagement} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}

