import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import SurveyRepairs from '../../components/SurveyRepairs';
import LoadingSkeleton from '../../components/LoadingSkeleton';

import { loadAllRepairs } from '../../actions/repairsActions';

import styles from './AllRepairs.module.css';

export default function AllRepairs() {
    const dispatch = useDispatch();
    const history = useHistory();

    const repairsSelector = useSelector((state) => state.repairs);
    const { allRepairs, loading } = repairsSelector;

    console.log(allRepairs);

    useEffect(() => {
        dispatch(loadAllRepairs());
    }, [dispatch]);

    return (
        <Fragment>
            <Header>Door Survey App</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div>
                    <button className={styles.previousSurveyButton} onClick={() => history.push('/')}>Back to Dashboard</button>
                </div>
                {loading ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        <h2>Repairs</h2>
                        <div className={styles.repairsContainer}>
                            {allRepairs ? _.values(allRepairs).map((repair, i) => (
                                <SurveyRepairs key={repair?.survey_id} surveyId={repair?.survey_id} label={repair?.label} doors={repair?.doors} />
                            )) : (
                                <div className={styles.surveysNotFound}>You have no repairs due.</div>
                            )}
                        </div>
                    </>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
