import axios from 'axios';
import Cookie from 'js-cookie';

import {
    LOAD_SURVEY_REQUEST,
    LOAD_SURVEY_SUCCESS,
    LOAD_SURVEY_FAILURE,
    LOAD_RECENT_SURVEYS_REQUEST,
    LOAD_RECENT_SURVEYS_SUCCESS,
    LOAD_RECENT_SURVEYS_FAILURE,
    LOAD_SURVEYS_DUE_REQUEST,
    LOAD_SURVEYS_DUE_SUCCESS,
    LOAD_SURVEYS_DUE_FAILURE,
    REMOVE_SURVEY_ENTRY_REQUEST,
    REMOVE_SURVEY_ENTRY_SUCCESS,
    REMOVE_SURVEY_ENTRY_FAILURE,
    REMOVE_SURVEY_REQUEST,
    REMOVE_SURVEY_SUCCESS,
    REMOVE_SURVEY_FAILURE
} from '../types/surveyDashboardTypes';

export const loadSurveyDashboard = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_SURVEY_REQUEST });

        const { data } = await axios.get(
            'https://hotel.napfis.co.uk/app_handler.php',
            {
                headers: {
                    'WG-Method': 'SURVEY_DASHBOARD',
                    'Wg-SurveyId': id,
                    'WG-Key': Cookie.get('accessToken')
                }
            }
        );

        dispatch({ type: LOAD_SURVEY_SUCCESS, payload: data });

        localStorage.setItem(
            'clientDataDisplay',
            JSON.stringify({
                hotel_name: data?.client_data?.hotel_name,
                survey_frequency: data?.client_data?.survey_frequency,
                survey_name: data?.client_data?.survey_name,
                survey_type: data?.client_data?.survey_type,
                survey_include_costs: data?.client_data?.survey_include_costs
            })
        );
    } catch (error) {
        dispatch({ type: LOAD_SURVEY_FAILURE });
    }
};

export const loadRecentSurveys = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_RECENT_SURVEYS_REQUEST });

        const { data } = await axios.get(
            'https://hotel.napfis.co.uk/app_handler.php',
            {
                headers: {
                    'WG-Method': 'SURVEY_DASHBOARD_RECENT_SURVEYS',
                    'Wg-SurveyId': id,
                    'WG-Key': Cookie.get('accessToken')
                }
            }
        );

        dispatch({ type: LOAD_RECENT_SURVEYS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LOAD_RECENT_SURVEYS_FAILURE });
    }
};

export const loadSurveysDue = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_SURVEYS_DUE_REQUEST });

        const { data } = await axios.get(
            'https://hotel.napfis.co.uk/app_handler.php',
            {
                headers: {
                    'WG-Method': 'SURVEY_DASHBOARD_SURVEYS_DUE',
                    'Wg-SurveyId': id,
                    'WG-Key': Cookie.get('accessToken')
                }
            }
        );

        dispatch({
            type: LOAD_SURVEYS_DUE_SUCCESS,
            payload: data?.surveys_due
        });
    } catch (error) {
        dispatch({ type: LOAD_SURVEYS_DUE_FAILURE });
    }
};

export const removeSurveyEntry = (id) => async (dispatch) => {
    try {
        dispatch({ type: REMOVE_SURVEY_ENTRY_REQUEST });

        const { data } = await axios.get(
            'https://hotel.napfis.co.uk/app_handler.php',
            {
                headers: {
                    'WG-Method': 'REMOVE_SURVEY_ENTRY',
                    'Wg-SurveyId': id,
                    'WG-Key': Cookie.get('accessToken')
                }
            }
        );

        dispatch({
            type: REMOVE_SURVEY_ENTRY_SUCCESS,
            payload: data?.surveys_due
        });
    } catch (error) {
        dispatch({ type: REMOVE_SURVEY_ENTRY_FAILURE });
    }
};

export const removeSurvey = (id) => async (dispatch) => {
    try {
        dispatch({ type: REMOVE_SURVEY_REQUEST });

        const { data } = await axios.get(
            'https://hotel.napfis.co.uk/app_handler.php',
            {
                headers: {
                    'WG-Method': 'REMOVE_SURVEY',
                    'Wg-SurveyId': id,
                    'WG-Key': Cookie.get('accessToken')
                }
            }
        );

        dispatch({ type: REMOVE_SURVEY_SUCCESS, payload: data?.surveys_due });
    } catch (error) {
        dispatch({ type: REMOVE_SURVEY_FAILURE });
    }
};
