import axios from 'axios';
import Cookie from 'js-cookie';

import {
    TOGGLE_MENU,
    CLOSE_MENU,
    NAVIGATION_REQUEST,
    NAVIGATION_FAIL,
    NAVIGATION_SUCCESS,
    GET_DEPENDENCIES_REQUEST,
    GET_DEPENDENCIES_SUCCESS,
    GET_DEPENDENCIES_FAIL,
    SEND_DATA_REQUEST,
    SEND_DATA_SUCCESS,
    SEND_DATA_FAILURE,
} from '../types/appTypes';
import { GET_COSTS_DEPENDENCIES_SUCCESS } from '../types/costsTypes';


export const toggleMenu = () => dispatch => {
    dispatch({ type: TOGGLE_MENU });
}

export const closeMenu = () => dispatch => {
    dispatch({ type: CLOSE_MENU });
}

export const setNavigation = () => async dispatch => {
    try {
        dispatch({ type: NAVIGATION_REQUEST });

        const navigation = await axios.get('https://hotel.napfis.co.uk/app_handler.php', { headers: {} });

        if (navigation) {
            dispatch({ type: NAVIGATION_SUCCESS, payload: navigation });
        }

    } catch (error) {
        dispatch({ type: NAVIGATION_FAIL });
    }
}

// export const getMenu = () => async dispatch => {
//     try {
//         dispatch({ type: MENU_REQUEST });

//         const { data } = await axios.get('https://hotel.napfis.co.uk/app_handler.php', { headers: { 'Wg-method': 'MENU', 'Wg-Key': Cookie.get('accessToken') } });

//         if (data) {
//             dispatch({ type: MENU_SUCCESS, payload: data });
//         }

//     } catch (error) {
//         console.error(error);
//         dispatch({ type: MENU_FAIL });
//     }
// };

export const getDependencies = () => async dispatch => {
    try {
        dispatch({ type: GET_DEPENDENCIES_REQUEST });

        const { data } = await axios.get('https://hotel.napfis.co.uk/app_handler.php', { headers: { 'WG-Method': 'DEPENDENCIES', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: GET_DEPENDENCIES_SUCCESS, payload: data?.dependencies });
        dispatch({ type: GET_COSTS_DEPENDENCIES_SUCCESS, payload: data?.dependencies });

        localStorage.setItem('dependencies', JSON.stringify(data?.dependencies));
    } catch (error) {
        dispatch({ type: GET_DEPENDENCIES_FAIL, payload: error?.response?.data.errors });
    }
};

export const sendHelpData = () => async dispatch => {
    const survey = localStorage.getItem('survey');
    const activeSurvey = localStorage.getItem('activeSurvey');
    const clientData = localStorage.getItem('clientData');
    const summary = localStorage.getItem('summary');
    const costsActive = localStorage.getItem('costsActive');
    const dependencies = localStorage.getItem('dependencies');
    const costs = localStorage.getItem('costs');
    const doors = localStorage.getItem('doors');
    const recentSurveys = localStorage.getItem('recentSurveys');

    let splitDoors = [];

    if (doors.length > 10000) {
        splitDoors = doors.match(/.{1,10000}/g);
    } else {
        splitDoors.push(doors);
    }

    try {
        dispatch({ type: SEND_DATA_REQUEST });

        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'survey', data: survey }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'activeSurvey', data: activeSurvey }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'clientData', data: clientData }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'summary', data: summary }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'costsActive', data: costsActive }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'dependencies', data: dependencies }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'costs', data: costs }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: 'recentSurveys', data: recentSurveys }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        
        splitDoors.forEach(async (door, i) => {
            await axios.post('https://hotel.napfis.co.uk/app_handler.php', { section: `doors[${i}]`, data: door }, { headers: { 'WG-Method': 'HELP_DATA', 'WG-Key': Cookie.get('accessToken') }});
        });
        
        dispatch({ type: SEND_DATA_SUCCESS });
        
    } catch (error) {
        dispatch({ type: SEND_DATA_FAILURE });
    }

};