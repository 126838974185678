import React, { Fragment, useState, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import Cookie from 'js-cookie';
import axios from 'axios';
import Localbase from 'localbase';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import doorSections from '../../constants/doorSections';
import Photo from '../../components/Photo';

import styles from './styles/PhotoManagement.module.css';

export default function PhotoManagement() {
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [uploadedList, setUploadedList] = useState([]);
    const [errorList, setErrorList] = useState([]);

    async function getPhotographs() {
        const db = new Localbase('napfis-hotelapp');

        const photos = await db.collection('door-photos').get();

        setPhotos(photos || []);
    }

    const handleUploadAll = () => {
        setUploadedList([]);
        setErrorList([]);
        setLoading(true);
        photos.forEach(async (photo) => {
            try {
                const fd = new FormData();
                const fieldName = photo.name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;
        
                fd.append(fieldName, photo.photo);
    
                const response = await axios.post('https://hotel.napfis.co.uk/app_handler.php', fd, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'multipart/form-data',
                        'WG-Method': 'SAVE_DOOR_PHOTO',
                        'Wg-Doorid': photo.id,
                        'Wg-Key': Cookie.get('accessToken')
                    }
                });
    
                if (response.status === 200) {
                    setUploadedList(prev => [...prev, { id: photo.id, name: photo.name }]);
                } else {
                    setErrorList(prev => [...prev, { id: photo.id, name: photo.name }]);
                }
            } catch (error) {
                setErrorList(prev => [...prev, { id: photo.id, name: photo.name }]);
            }
        });
        setLoading(false);
    }

    useEffect(() => {
        getPhotographs();
    }, []);

    return (
        <Fragment>
            <Header>Photo Management</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <h1>Photo Management</h1>
                <p style={{ textAlign: 'center' }}>View a list of photographs stored on your device.</p>
                <div className={styles.uploadAllContainer}>
                    {photos && (
                        <button className={styles.uploadAllButton} onClick={handleUploadAll}>Upload All<PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                    )}
                </div>
                <div className={styles.photoContainer}>
                    {photos.map((photo) => {
                        const uploaded = uploadedList.find((item) => item.id === photo.id && item.name === photo.name);
                        const error = errorList.find((item) => item.id === photo.id && item.name === photo.name);

                        return (
                            <Photo id={photo.id} name={photo.name} photo={photo.photo} uploaded={uploaded} error={error} />
                        );
                    })}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
